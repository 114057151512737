require("./bootstrap");

String.prototype.rupiah = function () {
    return "Rp." + new Intl.NumberFormat("id").format(parseInt(this));
};

String.prototype.ucwords = function () {
    return this.toLowerCase().replace(/\b[a-z]/g, (letter) =>
        letter.toUpperCase()
    );
};

window.delay = (callback, timeout = 800) => {
    if (timer) {
        clearTimeout(timer);
    }

    timer = setTimeout(() => {
        callback();
    }, timeout);
};
