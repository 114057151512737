import Echo from "laravel-echo";

require("dayjs/locale/id");
require("dayjs/locale/en");

window._ = require("lodash");
window.axios = require("axios");
window.Pusher = require("pusher-js");

window.dayjs = require("dayjs");
const customParseFormat = require("dayjs/plugin/customParseFormat");
window.dayjs.locale("id");
window.dayjs.extend(customParseFormat);

window.flatpickr_id = require("flatpickr/dist/l10n/id").default.id;
/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

window.Echo = new Echo({
    broadcaster: "pusher",
    key: "f5698bce0c9c9b478258",
    cluster: "ap1",
    forceTLS: true,
    authEndpoint: "/app/broadcast/auth",
    auth: {
        headers: {
            "X-CSRF-TOKEN": "{{ csrf_token() }}",
        },
    },
});

window.debounce = (callback, timeout = 325) => {
    let timeoutId = null;

    return (...args) => {
        window.clearTimeout(timeoutId);

        timeoutId = window.setTimeout(() => {
            callback.apply(null, args);
        }, timeout);
    };
};
